import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { extractCommonMutations, extractInitialItemMutations } from "../../../shared"
import extractActions from "./actions"

const baseURI = "/companies/shops"

const store = new StoreItemsModule({ baseURI: baseURI })

store.mergeState({
  isDirty: false,
  item: {},
  initialItem: {}
})

store.mergeMutations(extractCommonMutations())
store.mergeMutations(extractInitialItemMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
