import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"
import { extractCommonMutations, extractInitialItemMutations } from "../../../shared"

export default () => ({
  ...extractCommonMutations(),
  ...extractInitialItemMutations(),

  ADD_BUSINESS_HOUR: state => {
    state.item.business_hours.push({
      _addedAt: `t_${currentTimestamp()}`,
      day_off: false,
      start_date: null,
      end_date: null,
      open_time_in_minutes: null,
      close_time_in_minutes: null
    })
  },

  SET_BUSINESS_HOUR_BY_INDEX(state, { item, index }) {
    state.item.business_hours.splice(index, 1, item)
  },

  DELETE_BUSINESS_HOUR_BY_INDEX: (state, index) => {
    state.item.business_hours.splice(index, 1)
  }
})
