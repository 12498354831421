import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showSuccessToast } from "@/helpers/toasts"
import { cloneDeep } from "lodash-es"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, shopId) => {
    try {
      const { data } = await api.get(`${baseURI}/${shopId}/access_settings`)
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_ITEM: async ({ commit }, { shopId, ...params }) => {
    try {
      const { data } = await api.post(`${baseURI}/${shopId}/access_settings`, params)
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
      showSuccessToast({ text: i18n.t("company_system.shop_settings.access_setting_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
